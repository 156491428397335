import React, { useMemo } from "react";
import StandardPlayer from "./StandardPlayer";

const Player = props => {
    const { streamUrl } = props;
    const sldpStreamUrl = useMemo(() => streamUrl?.split(";")[0].trim(), [streamUrl]);
    return <StandardPlayer {...props} streamUrl={sldpStreamUrl} />;
};

export default Player;
