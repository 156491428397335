import React, { useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { getSDK } from "./utils";
const SLDP_LIB_URL = "https://softvelum.com/player/releases/sldp-vVERSION.min.js";
const SLDP_GLOBAL = "SLDP";

const DEFAULT_CLASSNAME = "sldp-react-player";

const StandardPlayer = ({
    sldpVersion = "2.24.0",
    streamUrl,
    width,
    height,
    controls = true,
    autoplay = false,
    muted = false,
    wrapperId = `sldp-react-player-${uuidv4()}`,
    sldpOptions = {
        adaptive_bitrate: {
            initial_rendition: "240p"
        },
        buffering: 2000
    }
}) => {
    const playerInstance = useRef({
        init: options => {},
        destroy: () => {}
    });

    useEffect(() => {
        if (wrapperId && streamUrl) {
            getSDK(SLDP_LIB_URL.replace("VERSION", sldpVersion), SLDP_GLOBAL).then(SLDP => {
                playerInstance.current = SLDP.init(
                    Object.assign({}, sldpOptions, {
                        container: wrapperId,
                        stream_url: streamUrl,
                        autoplay,
                        muted,
                        height,
                        width,
                        controls
                    })
                );
            });
        }

        return () => {
            if (playerInstance.current) playerInstance.current.destroy();
        };
    }, [streamUrl, wrapperId, autoplay, muted, height, width, controls]);

    return <div id={wrapperId} className={DEFAULT_CLASSNAME}></div>;
};

export default StandardPlayer;
