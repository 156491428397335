
import SldpPlayer from './SldpPlayer'

function App() {
  console.log("rendering app")
  return (        
       <SldpPlayer                                    
        wrapperId={"test1"}
        autoplay={true}
        muted={true}
        height={300}
        width={540} streamUrl="wss://vprismstreambk-cdn.vidgyor.com/sldp/test2"
      />
  );
}

export default App;
